import { BusinessImportanceLabel } from "$/business-importance";
import { CommonStatusMeanings } from "$/finding-types";
import { Dashboard2Activity, DashboardActivityCategory } from "$/dashboard/dashboard-response";
import { RiskSeverity } from "$/risk-severity";
import { getColorBySeverity } from "@/pages/inventory/details/sbom/components/sbom-component-common";
import { DataOptions } from "vuetify";

export interface DashboardFilterModel {
  minSeverity: RiskSeverity;
  minBusinessImportance: BusinessImportanceLabel;
  selectedStatuses: CommonStatusMeanings[];
  trendsBy: "product" | "status" | "severity";
  breakdownByChart: boolean;
  trendLines: boolean;
  search: string | null;
}

export const defaultDashboardFilterModel = Object.freeze({
  minSeverity: "high",
  minBusinessImportance: BusinessImportanceLabel.medium,
  selectedStatuses: ["new"],
  trendsBy: "severity",
  breakdownByChart: true,
  trendLines: false,
  search: null
} as const satisfies DashboardFilterModel);

export interface DashboardFilterModelV2 extends DashboardFilterModel {
  pagination: {
    total: number;
    options: Partial<DataOptions>;
  } | null;
}

export const defaultDashboardFilterModelV2 = Object.freeze({
  ...defaultDashboardFilterModel,
  pagination: null
} as const satisfies DashboardFilterModelV2);

export const STATUS_ICONS: Record<CommonStatusMeanings, string> = {
  new: "mdi-shield-key-outline",
  in_progress: "mdi-clock-time-four-outline",
  dismissed: "mdi-minus-circle-outline",
  resolved: "mdi-check-circle-outline"
};

export const STATUS_NAMES: Record<CommonStatusMeanings, string> = {
  new: "Open",
  in_progress: "In-Progress",
  dismissed: "Dismissed",
  resolved: "Resolved"
};
export const STATUS_ORDER: Record<CommonStatusMeanings, number> = {
  new: 4,
  in_progress: 3,
  dismissed: 2,
  resolved: 1
};
export const ACTIVITY_ICONS: Record<DashboardActivityCategory, string> = {
  "code-risks-fixed-default-branches": "mdi-doctor",
  "code-risks-fixed-feature-branches": "mdi-doctor",
  "high-epss-findings-open": "mdi-alert",
  "secrets-fixed": "mdi-doctor",
  "kev-findings-open": "mdi-bug",
  "pending-review-findings": "mdi-exclamation-thick"
};

const STATUS_COLORS_TO_SEVERITY: Record<CommonStatusMeanings, RiskSeverity> = {
  new: "critical",
  in_progress: "high",
  dismissed: "low",
  resolved: "info"
};

export const ACTIVITY_COLORS: Record<DashboardActivityCategory, string> = {
  "code-risks-fixed-default-branches": getColorBySeverity("low"),
  "code-risks-fixed-feature-branches": getColorBySeverity("info"),
  "high-epss-findings-open": getColorBySeverity("high"),
  "secrets-fixed": getColorBySeverity("info"),
  "kev-findings-open": getColorBySeverity("medium"),
  "pending-review-findings": getColorBySeverity("medium")
};

export const ACTIVITY_TITLES: Record<DashboardActivityCategory, string> = {
  "code-risks-fixed-default-branches": "Automated Triage",
  "code-risks-fixed-feature-branches": "Automated Triage",
  "secrets-fixed": "Automated Triage",
  "high-epss-findings-open": "Attention Required: High EPSS",
  "kev-findings-open": "Attention Required: KEV Findings",
  "pending-review-findings": "Pending Review"
};

export const ACTIVITY_SORT_ORDERS: Record<DashboardActivityCategory, number> = {
  "code-risks-fixed-default-branches": 5,
  "code-risks-fixed-feature-branches": 4,
  "secrets-fixed": 3,
  "high-epss-findings-open": 1,
  "kev-findings-open": 2,
  "pending-review-findings": 0
};

export type UIDashboardActivity = { title: string; icon: string; subtitle: string; color: string; tooltip?: string; sortOrder: number; originalActivity: Dashboard2Activity };

export const SEVERITIES: RiskSeverity[] = ["critical", "high", "medium", "low"];
export const BUSINESS_IMPORTANCE: BusinessImportanceLabel[] = ["high", "medium", "low"];

export function statusMeaningColor(status: CommonStatusMeanings): string {
  return getColorBySeverity(STATUS_COLORS_TO_SEVERITY[status]);
}
export function statusMeaningIcon(status: CommonStatusMeanings): string {
  return STATUS_ICONS[status];
}
export function statusMeaningText(status: CommonStatusMeanings): string {
  return STATUS_NAMES[status];
}
