<template>
  <v-app-bar
    v-if="showFullAppBar"
    app
    class="component layout-component app-bar app-bar-full"
    elevation="0"
    :expand-left="!$state.Layout.drawer.show"
    :sfa="$state.Auth.isNonDefaultOrgId"
    :light="$state.Auth.isNonDefaultOrgId || !$vuetify.theme.dark">
    <v-progress-linear :active="$state.Layout.loading" indeterminate color="primary" absolute bottom height="5px"></v-progress-linear>

    <arnica-logo v-if="!$state.Layout.drawer.show" class="logo" :background="$vuetify.theme.dark ? 'dark' : 'light'" />

    <v-icon left dense v-if="routeIcon">{{ routeIcon }}</v-icon>

    <!-- https://stackoverflow.com/questions/66607167/vuetify-v-app-bar-title-component-is-truncated-with-plenty-of-room-to-spare -->
    <v-app-bar-title class="text-no-wrap">
      {{ $state.Layout.title }}
    </v-app-bar-title>
    <v-spacer></v-spacer>
    <!--    <job-progress></job-progress>-->

    <template v-if="$state.Auth.isNonDefaultOrgId">
      <v-chip light label outlined @click.stop="copyOrgId">
        {{ $state.Auth.user?.userInfo?.org?.name }} <code>{{ $state.Auth.user?.userInfo?.org?.id }}</code>
        <v-icon small right>mdi-content-copy</v-icon>
        <v-icon small right @click.stop="$state.Layout.userMenu.editProfile = true">mdi-pencil</v-icon>
        <edit-profile-dialog></edit-profile-dialog>
      </v-chip>
      <v-spacer></v-spacer>
    </template>

    <!-- SCM Type -->
    <v-select v-if="scmTypes" v-model="$state.Layout.scmType" :items="scmTypes" style="max-width: 200px" class="ml-4 mr-4" dense filled hide-details outlined>
      <template v-slot:item="{ item }">
        <v-icon class="mr-2">{{ item.icon }}</v-icon>
        {{ item.text }}
      </template>
      <template v-slot:selection="{ item }">
        <v-icon class="mr-2">{{ item.icon }}</v-icon>
        {{ item.text }}
      </template>
    </v-select>

    <!-- Demo Data / Login-->
    <template v-if="$state.Auth.authenticated">
      <!-- <v-btn
        v-if="$state.DemoData.isDemoData"
				icon
				title="Remove Demo Data"
        cx-id="demo-data-remove"
				@click="$state.DemoData.isDemoData = false"
			>
				<v-icon>mdi-database-off</v-icon>
			</v-btn>
			<v-btn v-else cx-id="demo-data-start" icon title="See Demo Data" @click="$state.DemoData.isDemoData = true">
				<v-icon>mdi-database</v-icon>
			</v-btn> -->
    </template>
    <template v-else>
      <v-btn :to="{name: 'login'}" title="Sign In" color="primary" class="sign-in-btn"> Sign In </v-btn>
    </template>

    <!-- Help -->
    <v-btn icon @click.stop="toggleHelp" v-if="showHelp">
      <v-icon>mdi-help-circle</v-icon>
    </v-btn>

    <!-- Theme -->
    <v-btn icon @click.stop="toggleTheme">
      <v-icon v-if="$vuetify.theme.dark">mdi-white-balance-sunny</v-icon>
      <v-icon v-else>mdi-weather-night</v-icon>
    </v-btn>

    <!-- Notifications -->
    <v-btn v-scope.notifications_ro v-if="$state.Layout.onboarding.isDoneOnboarding" icon :to="{ name: 'notifications' }">
      <v-icon>mdi-bell</v-icon>
      <v-badge v-if="$state.Notifications.unreadCount > 0" :content="$state.Notifications.unreadCount" class="mr-0" color="red"></v-badge>
    </v-btn>

    <!-- User Menu -->
    <app-menu></app-menu>
  </v-app-bar>
  <div v-else class="component layout-component app-bar app-bar-partial">
    <v-btn icon @click.stop="toggleTheme">
      <v-icon v-if="$vuetify.theme.dark">mdi-white-balance-sunny</v-icon>
      <v-icon v-else>mdi-weather-night</v-icon>
    </v-btn>
  </div>
</template>

<script lang="ts">
import AppMenu from "./app-menu.vue";
import ArnicaLogo from "./arnica-logo.vue";
import { Component, Vue } from "vue-property-decorator";
import type { Integrations } from "@/state";
import EditProfileDialog from "@/components/layout/user-menu/edit-profile-dialog.vue";

@Component({
  components: {
    AppMenu,
    ArnicaLogo,
    EditProfileDialog
  }
})
export class AppBar extends Vue {
  protected get showFullAppBar(): boolean {
    return this.$route.meta?.appBar !== false;
  }

  protected get scmTypes(): typeof Integrations.scmTypes | null {
    if (!this.$route.meta?.scmDropDown) {
      return null;
    }

    const scmTypes = this.$state.Integrations.scmTypes;

    return scmTypes.length > 1 ? scmTypes : null;
  }

  protected get showHelp(): boolean {
    return this.$route.meta?.showHelp ?? false;
  }

  protected get routeIcon(): string | null {
    return this.$route.meta?.icon ?? null;
  }

  protected get backgroundColor(): string {
    return this.$state.Auth.isNonDefaultOrgId ? "warning" : "";
  }

  protected async toggleTheme(): Promise<void> {
    const dark = (this.$vuetify.theme.dark = !this.$vuetify.theme.dark);
    if (this.$state.Auth.scopes["registered"]) {
      await this.$state.Preferences.save({ dark });
    }
  }

  protected toggleHelp(): void {
    this.$state.Layout.help = !this.$state.Layout.help;
  }

  protected async dismissProductTour(): Promise<void> {
    if (this.$state.Layout.onboarding.dismissed) {
      return;
    }

    await this.$state.Preferences.save({ onboardingDismissed: true });
  }

  protected copyOrgId(): void {
    const orgId = this.$state.Auth.user?.userInfo?.org?.id;
    if (!orgId) {
      return;
    }
    try {
      navigator.clipboard.writeText(orgId);
      this.$state.Popup.info(`<code>${orgId}</code> copied to clipboard!`, { timeout: 1_000, html: true });
    } catch (e) {
      this.$state.Popup.error(String(e), { timeout: this.$state.Popup.INDEFINITELY });
    }
  }
}

export default AppBar;
</script>

<style lang="scss">
.component.layout-component.app-bar {
  &[sfa] {
    background: orange !important;

    .v-btn {
      color: rgba(0, 0, 0, 0.87) !important;
    }

    .v-icon {
      color: rgba(0, 0, 0, 0.87) !important;
    }
  }

  &[expand-left] {
    left: 0 !important;
  }

  &.app-bar-partial {
    position: absolute;
    right: 0;
    margin: 3px;
    z-index: 1000;
  }

  .logo {
    margin-right: 20px;
  }

  .sign-in-btn {
    margin-right: 1vh;
  }
}
</style>
