import { NavigationGuardNext, Route, RouteConfig } from "vue-router";
import { PolicyRuleTypes } from "$/dynamo";
import {
  ALL_FINDINGS_PAGE_NAME,
  CODE_PAGE_NAME,
  HARDENING_PAGE_NAME,
  InsightPageName,
  PERMISSIONS_PAGE_NAME,
  SECRETS_PAGE_NAME
} from "$/interfaces/ui-api/response/get-insight-response-item";
import { ADOHardeningReportQueryType, ADORiskReportQueryType, GitHubHardeningReportQueryType, GitHubRiskReportQueryType } from "$/report/risk-report/risk-report";
import { codeRiskTableConfig, secretsTableConfig, unifiedFindingsTableConfig } from "@/components/findings-table/findings-table-config";
import { Scope } from "@/interfaces/scope";
import { Layout } from "@/state";
import { FlagCheck } from "@/plugins/route-guards/sidebar-routes";

export function getRiskInsightPageRouteName(pageName: InsightPageName): `risks.${typeof pageName}` {
  return `risks.${pageName}`;
}

function findingsScopes(scopes: PartialRecord<Scope, true | undefined>): boolean {
  const { finding_ro, finding_single_ro, user_findings_ro, repository_findings_ro, user_single_finding_ro, repository_single_finding_ro } = scopes;
  return !!(finding_ro || finding_single_ro || user_findings_ro || repository_findings_ro || user_single_finding_ro || repository_single_finding_ro);
}

export const risksPageRoutes: RouteConfig[] = [
  {
    path: "/risks",
    name: "risks",
    component: async () => import(/* webpackChunkName: "risks" */ "@/pages/risks/risks-page.vue"),
    meta: {
      scopes: [Scope.registered],
      sidebar: true,
      title: "Risks",
      icon: "mdi-shield-home"
    },
    children: [
      {
        path: "dashboard",
        name: "dashboard",
        component: async () => import(/* webpackChunkName: "dashboard2" */ "@/pages/dashboard/dashboard.vue"),
        meta: {
          sidebar: FlagCheck.productsV1.front.isTrue,
          title: "Dashboard",
          icon: "mdi-monitor-dashboard",
          showHelp: true,
          scopes: [Scope.insights_ro]
        }
      },
      {
        path: "dashboard-v2",
        name: "dashboard-v2",
        component: async () => import(/* webpackChunkName: "dashboard-v2" */ "@/pages/dashboard/dashboard-v2.vue"),
        meta: {
          sidebar: FlagCheck.productsV2.front.isTrue,
          title: "Dashboard",
          icon: "mdi-monitor-dashboard",
          showHelp: true,
          scopes: [Scope.insights_ro]
        }
      },
      {
        path: "git-posture",
        component: () => import(/* webpackChunkName: "risks" */ "@/pages/git-posture-page.vue"),
        meta: {
          sidebar: FlagCheck.jobsV2.inventoryV2.isFalse,
          title: "Git Posture",
          icon: "mdi-git",
          scmDropDown: true,
          scopes: [Scope.git_posture_ro]
        },
        children: [
          {
            path: "",
            name: "git-posture-page",
            component: () => import(/* webpackChunkName: "risks" */ "@/pages/git-posture.vue"),
            meta: {
              scopes: [Scope.registered],
              sidebar: false,
              icon: "mdi-git",
              scmDropDown: true
            }
          },
          {
            path: "permissions/:scmType?",
            name: getRiskInsightPageRouteName(PERMISSIONS_PAGE_NAME),
            component: () => import(/* webpackChunkName: "risks-hardening" */ "@/components/risks/risks-tabs-page.vue"),
            props: {
              page: PERMISSIONS_PAGE_NAME
            },
            meta: {
              scopes: [Scope.registered],
              sidebar: false,
              title: "Permissions",
              icon: "mdi-shield-account",
              scmDropDown: true
            },
            children: [
              //NOTE: we add gh- or ado- as a prefix although the :scmType is supposedly enough to make it unique
              // the reason is that without it, hitting refresh - the vue router needs to figure out what route we are in,
              // and since nothing ties to the scmType, it has to stay unique so it can trigger the right component
              {
                path: "gh-org-owner",
                name: GitHubRiskReportQueryType.GHORGOWNER,
                component: () =>
                  import(
                    /* webpackChunkName: "gh-excessive-org-owner-report" */ "@/pages/risks/permissions/permission-reports/github-permission-report/excessive-org-owner-report/excessive-org-owner-report.vue"
                  ),
                meta: {
                  scopes: [Scope.registered],
                  title: "Excessive Org Owner Permissions",
                  sidebar: false,
                  scmDropDown: true,
                  icon: "mdi-shield-account"
                }
              },
              {
                path: "gh-repo-admin",
                //TODO: route names should be globally unique, this might need a prefix...
                name: GitHubRiskReportQueryType.GHREPOADMIN,
                component: () =>
                  import(
                    /* webpackChunkName: "gh-excessive-repo-admin-report" */ "@/pages/risks/permissions/permission-reports/github-permission-report/excessive-repo-admin-report.vue"
                  ),
                meta: {
                  scopes: [Scope.registered],
                  title: "Excessive Repository Admin Permissions",
                  sidebar: false,
                  scmDropDown: true,
                  icon: "mdi-shield-account"
                }
              },
              {
                path: "gh-excessive-maintain",
                name: GitHubRiskReportQueryType.GHMAINTAIN,
                component: () =>
                  import(
                    /* webpackChunkName: "gh-excessive-maintain-report" */ "@/pages/risks/permissions/permission-reports/github-permission-report/excessive-maintain-report.vue"
                  ),
                meta: {
                  scopes: [Scope.registered],
                  title: "Excessive Maintain Permissions",
                  sidebar: false,
                  scmDropDown: true,
                  icon: "mdi-shield-account"
                }
              },
              {
                path: "gh-excessive-write",
                name: GitHubRiskReportQueryType.GHWRITE,
                component: () =>
                  import(/* webpackChunkName: "gh-excessive-write-report" */ "@/pages/risks/permissions/permission-reports/github-permission-report/excessive-write-report.vue"),
                meta: {
                  scopes: [Scope.registered],
                  title: "Excessive Write Permissions",
                  sidebar: false,
                  scmDropDown: true,
                  icon: "mdi-shield-account"
                }
              },
              {
                path: "gh-excessive-codeowners",
                name: GitHubRiskReportQueryType.GHCODEOWNERS,
                component: () =>
                  import(
                    /* webpackChunkName: "gh-excessive-codeowners-report" */ "@/pages/risks/permissions/permission-reports/github-permission-report/excessive-codeowners-report.vue"
                  ),
                meta: {
                  scopes: [Scope.registered],
                  title: "Excessive CODEOWNERS Permissions",
                  sidebar: false,
                  scmDropDown: true,
                  icon: "mdi-shield-account"
                }
              },
              {
                path: "gh-excessive-apps",
                name: GitHubRiskReportQueryType.GHAPP,
                component: () =>
                  import(/* webpackChunkName: "gh-excessive-apps-report" */ "@/pages/risks/permissions/permission-reports/excessive-apps-report/excessive-apps-report.vue"),
                meta: {
                  scopes: [Scope.registered],
                  title: "Excessive App Permissions",
                  sidebar: false,
                  scmDropDown: true,
                  icon: "mdi-shield-account"
                }
              },
              {
                path: "ado-excessive-admin",
                name: ADORiskReportQueryType.ADOADMIN,
                component: () =>
                  import(/* webpackChunkName: "ado-excessive-admin-report" */ "@/pages/risks/permissions/permission-reports/ado-permission-report/excessive-ado-admins-report.vue"),
                meta: {
                  scopes: [Scope.registered],
                  title: "Excessive Admin Rights",
                  sidebar: false,
                  scmDropDown: true,
                  icon: "mdi-shield-account"
                }
              },
              {
                path: "ado-excessive-pr-approvers",
                name: ADORiskReportQueryType.ADOPRAPPROVERS,
                component: () =>
                  import(
                    /* webpackChunkName: "ado-excessive-pr-approvers-report" */ "@/pages/risks/permissions/permission-reports/ado-permission-report/excessive-pr-approvers-report.vue"
                  ),
                meta: {
                  scopes: [Scope.registered],
                  title: "Excessive Pull Request Approvers",
                  sidebar: false,
                  scmDropDown: true,
                  icon: "mdi-shield-account"
                }
              },
              {
                path: "ado-excessive-code-contributors",
                name: ADORiskReportQueryType.ADOCODECONTRIBUTORS,
                component: () =>
                  import(
                    /* webpackChunkName: "ado-excessive-code-contributors-report" */ "@/pages/risks/permissions/permission-reports/ado-permission-report/excessive-code-contributors-report.vue"
                  ),
                meta: {
                  scopes: [Scope.registered],
                  title: "Excessive Code Contributors",
                  sidebar: false,
                  scmDropDown: true,
                  icon: "mdi-shield-account"
                }
              }
            ]
          },
          {
            path: "hardening/unenforceable-policy",
            redirect() {
              return `/risks/git-posture/hardening/${Layout.scmType}/${Layout.scmType?.toLocaleLowerCase()}-unenforceable-policy`;
            },
            meta: {
              scopes: [Scope.registered],
              sidebar: false,
              icon: "mdi-shield-edit"
            }
          },
          {
            path: "hardening/:scmType?",
            name: getRiskInsightPageRouteName(HARDENING_PAGE_NAME),
            component: () => import(/* webpackChunkName: "risks-hardening" */ "@/components/risks/risks-tabs-page.vue"),
            props: {
              page: HARDENING_PAGE_NAME
            },
            meta: {
              scopes: [Scope.registered],
              sidebar: false,
              title: "Hardening",
              icon: "mdi-shield-edit",
              scmDropDown: true
            },
            children: [
              {
                path: "ado-inactive-users",
                name: ADOHardeningReportQueryType.ADOInactiveUsers,
                component: () => import(/* webpackChunkName: "ado-stale-users" */ "@/pages/risks/hardening/details/ado-stale-users/ado-stale-users.vue"),
                meta: {
                  scopes: [Scope.registered],
                  title: "Inactive Users",
                  icon: "mdi-shield-edit",
                  scmDropDown: true
                }
              },
              {
                path: "ado-stale-repos",
                name: ADOHardeningReportQueryType.ADOStaleRepositories,
                component: () => import(/* webpackChunkName: "ado-stale-repos" */ "@/pages/risks/hardening/details/ado-stale-repos/ado-stale-repos.vue"),
                meta: {
                  scopes: [Scope.registered],
                  title: "Stale Repositories",
                  icon: "mdi-shield-edit",
                  scmDropDown: true
                }
              },
              {
                path: "ado-accessible-webhooks",
                name: ADOHardeningReportQueryType.ADOAccessibleWebhooks,
                component: () => import(/* webpackChunkName: "ado-accessible-webhooks" */ "@/pages/risks/hardening/details/ado-accessible-webhooks/ado-accessible-webhooks.vue"),
                meta: {
                  scopes: [Scope.registered],
                  title: "Anonymously Accessible Webhooks",
                  icon: "mdi-shield-edit",
                  scmDropDown: true
                }
              },
              {
                path: "ado-unenforceable-policy",
                name: ADOHardeningReportQueryType.ADOUnenforceablePolicy,
                component: () => import(/* webpackChunkName: "ado-unenforceable-policy" */ "@/pages/risks/hardening/details/ado-unenforceable-policy/ado-unenforceable-policy.vue"),
                props: {
                  riskReportMode: true
                },
                meta: {
                  scopes: [Scope.registered],
                  title: "Unenforceable Policies",
                  icon: "mdi-shield-edit",
                  scmDropDown: true
                }
              },
              {
                path: "gh-inactive-users",
                name: GitHubHardeningReportQueryType.GHInactiveUsers,
                component: () => import(/* webpackChunkName: "github-stale-users" */ "@/pages/risks/hardening/details/github-stale-users/github-stale-users.vue"),
                props: {
                  riskReportMode: true
                },
                meta: {
                  scopes: [Scope.registered],
                  title: "Inactive Users",
                  icon: "mdi-shield-edit",
                  scmDropDown: true
                }
              },
              {
                path: "gh-stale-repos",
                name: GitHubHardeningReportQueryType.GHStaleRepositories,
                component: () => import(/* webpackChunkName: "gh-stale-repos" */ "@/pages/risks/hardening/details/github-stale-repos/github-stale-repos.vue"),
                props: {
                  riskReportMode: true
                },
                meta: {
                  scopes: [Scope.registered],
                  title: "Stale Repositories",
                  icon: "mdi-shield-edit",
                  scmDropDown: true
                }
              },
              {
                path: "gh-misconfig-codeowners",
                name: GitHubHardeningReportQueryType.GHMisconfiguredCodeowners,
                component: () =>
                  import(
                    /* webpackChunkName: "github-misconfigured-codeowners" */ "@/pages/risks/hardening/details/github-misconfigured-codeowners/github-misconfigured-codeowners.vue"
                  ),
                props: {
                  riskReportMode: true
                },
                meta: {
                  scopes: [Scope.registered],
                  title: "Misconfigured CODEOWNERS",
                  icon: "mdi-shield-edit",
                  scmDropDown: true
                }
              },
              {
                path: "github-unenforceable-policy",
                name: GitHubHardeningReportQueryType.GHUnenforceablePolicy,
                component: () =>
                  import(/* webpackChunkName: "github-unenforceable-policy" */ "@/pages/risks/hardening/details/github-unenforceable-policy/github-unenforceable-policy.vue"),
                meta: {
                  scopes: [Scope.registered],
                  title: "Unenforceable Policies",
                  icon: "mdi-shield-edit",
                  scmDropDown: true
                }
              },
              {
                path: "gh-accessible-webhooks",
                name: GitHubHardeningReportQueryType.GHAccessibleWebhooks,
                component: () =>
                  import(/* webpackChunkName: "github-unenforceable-policy" */ "@/pages/risks/hardening/details/github-accessible-webhooks/github-accessible-webhooks.vue"),
                meta: {
                  scopes: [Scope.registered],
                  title: "Anonymously Accessible Webhooks",
                  icon: "mdi-shield-edit",
                  scmDropDown: true
                }
              }
            ]
          },
          {
            path: "dismissed",
            name: "dismissed",
            component: () => import(/* webpackChunkName: "risks-dismissed" */ "@/pages/risks/dismissed-items-page.vue"),
            meta: {
              scopes: [Scope.registered],
              sidebar: false,
              title: "Exceptions",
              icon: "mdi-shield-remove",
              scmDropDown: true
            }
          },
          {
            path: "mitigations",
            name: "mitigations",
            component: () => import(/* webpackChunkName: "risks-mitigations" */ "@/pages/events/mitigations-page.vue"),
            meta: {
              scopes: [Scope.registered],
              sidebar: false,
              title: "Mitigations",
              icon: "mdi-shield-check",
              scmDropDown: true
            }
          },
          {
            path: "permission-activity",
            name: "permission-activity",
            component: () => import(/* webpackChunkName: "permission-activity" */ "@/pages/events/permission-activity-page.vue"),
            meta: {
              scopes: [Scope.registered],
              sidebar: false,
              title: "Automation",
              icon: "mdi-account-reactivate"
            }
          }
        ]
      },
      {
        path: "policy/self-service",
        name: "self-service-policy",
        component: () => import(/* webpackChunkName: "admin" */ "@/pages/admin/policy/policy-page.vue"),
        props: {
          policyType: PolicyRuleTypes.EXCESSIVE_PERMISSIONS
        },
        meta: {
          scopes: [Scope.registered],
          sidebar: false,
          title: "Self-Service Policy",
          icon: "mdi-arrow-decision"
        }
      },
      {
        path: "secrets/:sortKey?",
        name: SECRETS_PAGE_NAME,
        component: async () => import(/* webpackChunkName: "findings-table" */ "@/components/findings-table/findings-table.vue"),
        props: {
          config: secretsTableConfig
        },
        meta: {
          sidebar: true,
          title: "Secrets",
          icon: "mdi-form-textbox-password",
          scopes: findingsScopes
        }
      },
      {
        path: "all",
        name: ALL_FINDINGS_PAGE_NAME,
        component: async () => import(/* webpackChunkName: "findings-table" */ "@/components/findings-table/findings-table.vue"),
        props: (route) => ({
          config: route.params["config"] ?? unifiedFindingsTableConfig,
          //TODO: this can be part of the config...
          product: route.params["product"] ?? null,
          productV2: route.params["productV2"] ?? null
        }),
        meta: {
          sidebar: false,
          icon: "mdi-shield-home",
          scopes: [Scope.finding_ro]
        }
      },
      {
        path: "code/:sortKey?",
        name: CODE_PAGE_NAME,
        component: async () => import(/* webpackChunkName: "findings-table" */ "@/components/findings-table/findings-table.vue"),
        props: (route) => ({
          config: route.params["config"] ?? codeRiskTableConfig
        }),
        meta: {
          sidebar: true,
          title: "Code",
          icon: "mdi-code-json",
          scopes: findingsScopes
        }
      }
    ]
  }
];
