import Vue from "vue";
import { DashboardActivityCategory } from "$/dashboard/dashboard-response";
import { ScmIntegrationType } from "$/dynamo";
import { DashboardAssetFilter, DateFilter, FindingFilter } from "$/dynamo/findingBase";
import { CodeRiskFindingType, CodeRiskStates, FindingActivity, FindingStatuses, SecretFindingType, SemgrepStates, StatusUtils } from "$/finding-types";
import { VerificationResults } from "$/interfaces/ui-api/response/get-secrets-response";
import { ScmAssetHeaderFilterModel2 } from "@/components/asset/scm-asset-filter.model2";
import { HeaderType, type FindingsType } from "@/components/findings-table/findings-table-config";
import { UICodeRiskFinding } from "@/pages/risks/code/ui-code-risk-item";
import { UNIFIED_FINDING_STATUSES } from "@/pages/risks/secrets/secrets-constants";

export type FilterPredicate = <K extends keyof ICodeRiskFilters>(filters: ICodeRiskFilters, item: UICodeRiskFinding, key: K) => boolean;
type CodeRiskAndSecretsFindingType = CodeRiskFindingType | SecretFindingType;
//TODO: since secrets are now code risks, perhaps replace CodeRisk with "Risk" or "Finding" for all identifiers in this file (and this file itself)

export interface ICodeRiskFilters extends ScmAssetHeaderFilterModel2 {
  "metadata.status": FindingStatuses[];
  risk: string[];
  dataType: CodeRiskAndSecretsFindingType[];
  sortKeys: string[];
  activity: FindingActivity[];
  activityCategory: DashboardActivityCategory | undefined;
  //secrets filters (Secret is now a CodeRisk for implementation reasons)
  verificationResult: (keyof typeof VerificationResults)[] | undefined;
  specificType: string[] | undefined;
  //SCA filters
  licenseName: string[];
  packageType: string[];
  packageName: string[];
  isDevDependency: boolean | undefined;
  hasDirectVulnerabilities: boolean | undefined;
  hasFix: boolean | undefined;
  productSortKey: string[] | undefined;
  products: string[] | undefined;
  vulnerabilityClass: string[];
  fileExtension: string[];
  reachabilityConfidence: string[];
  isOwaspTopTen: boolean | undefined;
  licenseClassification: string[];
  createdAt: DateFilter | undefined;
  updatedAt: DateFilter | undefined;
  lastDetectedAt: DateFilter | undefined;
  packageReleaseDate: DateFilter | undefined;
  latestVersionReleaseDate: DateFilter | undefined;
  slaStart: DateFilter | undefined;
}

export type BooleanFilterKeys = KeysOfType<ICodeRiskFilters, boolean | undefined>;
export type StringArrayFilterKeys = KeysOfType<ICodeRiskFilters, string[] | undefined>;

export const CODE_RISK_EMPTY_FILTERS: ICodeRiskFilters = {
  integrationType: [],
  "metadata.status": [],
  project: [],
  integrationOrgId: [],
  repo: [],
  branch: [],
  risk: [],
  dataType: [],
  sortKeys: [],
  activity: [],
  activityCategory: undefined,
  verificationResult: [],
  specificType: [],
  licenseName: [],
  packageType: [],
  packageName: [],
  isDevDependency: undefined,
  hasDirectVulnerabilities: undefined,
  hasFix: undefined,
  productSortKey: undefined,
  products: undefined,
  vulnerabilityClass: [],
  isOwaspTopTen: undefined,
  fileExtension: [],
  reachabilityConfidence: [],
  licenseClassification: [],
  createdAt: undefined,
  updatedAt: undefined,
  lastDetectedAt: undefined,
  packageReleaseDate: undefined,
  latestVersionReleaseDate: undefined,
  slaStart: undefined
};

export const CODE_RISK_DEFAULT_FILTERS: ICodeRiskFilters = {
  ...CODE_RISK_EMPTY_FILTERS,
  //risk: (["critical", "high", "medium"] as const).map(r=>RISK_CONVERTER[r].toString()),
  "metadata.status": Object.entries(UNIFIED_FINDING_STATUSES)
    .filter(([, { value }]) => StatusUtils.isOpen(value))
    .map(([key]) => key as CodeRiskStates)
};

export const FILTER_PREDICATES: Partial<Record<keyof ICodeRiskFilters, FilterPredicate>> = {
  "metadata.status": (filters, item) => {
    if (!filters["metadata.status"].length) {
      return true;
    }
    const status = item.metadata?.status;
    return !!status && filters["metadata.status"].includes(status as SemgrepStates);
  },
  sortKeys: (filters, item) => {
    if (!filters.sortKeys.length) {
      return true;
    }
    return filters.sortKeys.includes(item.sortKey);
  }
} as const;

export function DEFAULT_PREDICATE<K extends keyof ICodeRiskFilters & keyof UICodeRiskFinding>(filters: ICodeRiskFilters, item: UICodeRiskFinding, key: K): boolean {
  const filter = filters[key];
  // this entire piece of code is no longer used (only for the old code-risk-page)
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  if (!filter?.length) {
    return true;
  }
  const value = item[key];

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore Argument of type 'string' is not assignable to parameter of type 'never'.ts(2345)
  return value && filter.includes(value);
}

export const CodeRiskFilterKeysToFindingFilterKeys: Record<keyof ICodeRiskFilters, keyof FindingFilter | null> = {
  "metadata.status": "metadataStatus",
  risk: "severity",
  dataType: "dataType",
  activity: "activity",
  isDevDependency: "isDevDependency",
  hasDirectVulnerabilities: "hasDirectVulnerabilities",
  hasFix: "hasFix",
  licenseName: "licenseName",
  packageType: "packageType",
  packageName: "packageName",
  integrationType: "integrationType",
  integrationOrgId: "orgId",
  project: "project",
  repo: "repo",
  branch: "branch",
  verificationResult: "verificationResult",
  specificType: "specificType",
  sortKeys: "sortKey",
  activityCategory: "dashboardActivityCategory",
  productSortKey: "productSortKey",
  products: "products",
  vulnerabilityClass: "vulnerabilityClass",
  fileExtension: "fileExtension",
  reachabilityConfidence: "reachabilityConfidence",
  isOwaspTopTen: "isOwaspTopTen",
  licenseClassification: "licenseClassification",
  createdAt: "createdAt",
  updatedAt: "updatedAt",
  lastDetectedAt: "lastDetectedAt",
  packageReleaseDate: "packageReleaseDate",
  latestVersionReleaseDate: "latestVersionReleaseDate",
  slaStart: "slaStart"
};

export const HeaderTypeToFindingFilterKeys: Record<HeaderType, Array<keyof FindingFilter> | null> = {
  "metadata.status": ["metadataStatus"],
  risk: ["severity"],
  dataType: ["dataType"],
  activity: ["activity"],
  isDevDependency: ["isDevDependency"],
  hasDirectVulnerabilities: ["hasDirectVulnerabilities"],
  hasFix: ["hasFix"],
  packageType: ["packageType"],
  packageName: ["packageName"],
  asset: ["integrationType", "orgId", "project", "repo", "branch"],
  verificationResult: ["verificationResult"],
  specificType: ["specificType"],
  "metadata.additionalInfo": null,
  license: ["licenseName"],
  detectedOn: null,
  issue: null,
  path: null,
  title: null,
  firstDetectedOn: null,
  highestEPSSScore: null,
  createdAt: ["createdAt"],
  updatedAt: ["updatedAt"],
  lastDetectedAt: ["lastDetectedAt"],
  packageReleaseDate: ["packageReleaseDate"],
  latestVersionReleaseDate: ["latestVersionReleaseDate"],
  slaStart: ["slaStart"],
  isOwaspTopTen: ["isOwaspTopTen"],
  vulnerabilityClass: ["vulnerabilityClass"],
  fileExtension: ["fileExtension"],
  reachabilityConfidence: ["reachabilityConfidence"],
  licenseClassification: ["licenseClassification"],
  criticalCVEsCount: null,
  highCVEsCount: null,
  latestVersion: null,
  openSSFScorecardReputation: null,
  totalCVEsCount: null,
  lastUpdateDate: null
};

export class CodeRiskFilters implements ICodeRiskFilters {
  private static readonly STORAGE_KEY_PREFIX: string = "filters";
  readonly #type: FindingsType;
  readonly #predicates: [keyof ICodeRiskFilters, FilterPredicate][];

  public integrationType!: ScmIntegrationType[];
  public "metadata.status"!: FindingStatuses[];
  public integrationOrgId!: string[];
  public project!: string[];
  public repo!: string[];
  public branch!: string[];
  public risk!: string[];
  public dataType!: CodeRiskAndSecretsFindingType[];
  public sortKeys!: string[];
  public activity!: FindingActivity[];
  public activityCategory: DashboardActivityCategory | undefined;
  public assetFilters: DashboardAssetFilter[] | undefined;
  //since secrets are now "code risks" from a data structure perspective, we need to be able to filter by verification result, secret type
  public verificationResult: (keyof typeof VerificationResults)[] | undefined;
  public specificType: string[] | undefined;

  public licenseName!: string[];
  public packageType!: string[];
  public packageName!: string[];
  public isDevDependency: boolean | undefined;
  public hasDirectVulnerabilities: boolean | undefined;
  public hasFix: boolean | undefined;
  public productSortKey!: string[] | undefined;
  public products!: string[] | undefined;
  public vulnerabilityClass!: string[];
  public isOwaspTopTen: boolean | undefined;
  public fileExtension!: string[];
  public reachabilityConfidence!: string[];
  public licenseClassification!: string[];
  public createdAt!: DateFilter;
  public updatedAt!: DateFilter;
  public lastDetectedAt!: DateFilter;
  public packageReleaseDate!: DateFilter;
  public latestVersionReleaseDate!: DateFilter;
  public slaStart!: DateFilter;

  public constructor(type: FindingsType, obj?: Partial<ICodeRiskFilters>) {
    this.#type = type;
    obj ??= CodeRiskFilters.load(type);
    const filters = { ...CODE_RISK_DEFAULT_FILTERS, ...obj };
    Object.assign(this, filters);
    Vue.observable(this);
    this.#predicates = Object.keys(filters).map(
      (k) => [k as keyof ICodeRiskFilters, FILTER_PREDICATES[k as keyof ICodeRiskFilters] ?? DEFAULT_PREDICATE] as [keyof ICodeRiskFilters, FilterPredicate]
    );
  }

  public get type(): FindingsType {
    return this.#type;
  }

  private set type(_value: FindingsType) {
    // noop
  }

  // for compatibility with secrets filters
  public get status(): FindingStatuses[] {
    return this["metadata.status"];
  }

  public set status(value: FindingStatuses[]) {
    this["metadata.status"] = value;
  }

  public match(items: UICodeRiskFinding[]): UICodeRiskFinding[] {
    this.save(); // this method is called on every filter change -> save to storage
    const predicates = this.#predicates;
    return items?.filter((i) => predicates.every(([key, filter]) => filter(this, i, key)));
  }

  private static load(type: FindingsType): Partial<ICodeRiskFilters> {
    const storageKey = this.getStorageKey(type);
    const json = window.localStorage.getItem(storageKey) || "{}";
    const obj = JSON.parse(json);
    return obj;
  }

  public static reset(type: FindingsType): CodeRiskFilters {
    const storageKey = this.getStorageKey(type);
    window.localStorage.removeItem(storageKey);
    return new CodeRiskFilters(type);
  }

  /**
   * reset restore filters to their default, and default includes only open and in progress statuses.
   * the use case is that if we have sort key or sort keys, we don't need to set the status filter, we should show any item based on sort key
   */
  public static clear(type: FindingsType): CodeRiskFilters {
    const filters = new CodeRiskFilters(type, CODE_RISK_EMPTY_FILTERS);
    filters.save();
    return filters;
  }

  private static getStorageKey(type: FindingsType): string {
    return `${CodeRiskFilters.STORAGE_KEY_PREFIX}:${type}`;
  }

  public save(): void {
    const json = JSON.stringify(this);
    const storageKey = CodeRiskFilters.getStorageKey(this.type);
    window.localStorage.setItem(storageKey, json);
  }

  public update(key: keyof ICodeRiskFilters, value: unknown): void {
    const isArray = Array.isArray(this[key]);
    (this[key] as typeof value) = value ?? (isArray ? [] : null);
    this.save();
  }

  public get hasDefaultFilters(): boolean {
    for (const [key, defaultValue] of Object.entries(CODE_RISK_DEFAULT_FILTERS)) {
      const currentValue = this[key as keyof ICodeRiskFilters];
      //compare arrays
      if (Array.isArray(currentValue) && Array.isArray(defaultValue) && !Array.hasSameElements(currentValue, defaultValue)) {
        return false;
      }
      if (currentValue !== defaultValue) {
        return false;
      }
    }
    return true;
  }
}
