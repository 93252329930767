import { IssuesIntegrationType } from "../../../dynamo";
import { PolicyItemType } from "../policy-item-base";
import { PolicyAction, PolicyActionSubType } from "./policy-action";
import { FieldMapping } from "../../../issues/field-mapping";
import { uuid } from "../../../ui-models/uuid";

declare module "./policy-action" {
  enum PolicyActionSubType {
    /**
     * @deprecated Use `create_issue_inline` instead
     */
    create_issue = "create_issue",
    create_issue_inline = "create_issue_inline",
    create_issue_product_mapped = "create_issue_product_mapped"
  }
}

(PolicyActionSubType as { create_issue: "create_issue" }).create_issue = "create_issue";
(PolicyActionSubType as { create_issue_inline: "create_issue_inline" }).create_issue_inline = "create_issue_inline";
(PolicyActionSubType as { create_issue_product_mapped: "create_issue_product_mapped" }).create_issue_product_mapped = "create_issue_product_mapped";

/**
 * @deprecated Use `PolicyActionCreateInlineIssue` instead. This only exists for backward compatibility.
 * All policies of this type will be converted to `PolicyActionCreateInlineIssue` (See `mapPolicyCreateIssue` in `PolicyAdaptorBase`)
 */
export interface PolicyActionCreateIssue extends PolicyAction, IssueRule {
  sub: PolicyActionSubType.create_issue;
}

export interface PolicyActionCreateIssueInline extends PolicyAction, IssueRule {
  sub: PolicyActionSubType.create_issue_inline;
}

export interface PolicyActionCreateIssueProductMapped extends PolicyAction {
  sub: PolicyActionSubType.create_issue_product_mapped;
}

export type IssueFields = Record<string, FieldMapping>;

export interface IssueRule {
  integration: {
    orgId: string;
    type: IssuesIntegrationType;
  };
  issue: {
    /** Project ID */
    project: string;
    /** Issue type ID */
    type: string;
    status: {
      /** Open status ID */
      open: string;
      /** Closed status ID */
      close: string;
    };
    /** Optional assignee */
    assignee: string | null;
    /** Field mapping */
    fields: IssueFields;
  };
  /** Optional footer for created issue */
  footer: string | null;
  /**
   * The uuid of the issue rule that created the issue (applies to issues created through product issue management)
   */
  issueRuleId?: uuid;
}

export const IssueRule = {
  generateEmpty(): IssueRule {
    const empty = {
      integration: null,
      issue: {
        project: "",
        type: "",
        status: {
          open: null,
          close: null
        },
        assignee: null,
        fields: {}
      },
      footer: null,
      issueRuleId: null
    } as unknown as IssueRule;
    return empty;
  }
} as const;

export const PolicyActionCreateIssue = {
  component: {
    type: PolicyItemType.action,
    sub: PolicyActionSubType.create_issue
  },
  is(action: PolicyAction): action is PolicyActionCreateIssue {
    return action.sub === PolicyActionSubType.create_issue;
  }
} as const;

export const PolicyActionCreateIssueInline = {
  component: {
    type: PolicyItemType.action,
    sub: PolicyActionSubType.create_issue_inline
  },
  is(action: PolicyAction): action is PolicyActionCreateIssueInline {
    return action.sub === PolicyActionSubType.create_issue_inline;
  }
} as const;

export const PolicyActionCreateIssueProductMapped = {
  component: {
    type: PolicyItemType.action,
    sub: PolicyActionSubType.create_issue_product_mapped
  },
  is(action: PolicyAction): action is PolicyActionCreateIssueProductMapped {
    return action.sub === PolicyActionSubType.create_issue_product_mapped;
  }
} as const;
