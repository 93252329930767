import { CommunicationIntegrationType, ScmIntegrationType } from "../../dynamo";
import { CodeRiskFindingType, SecretFindingType } from "../../finding-types";
import { Tier } from "../ui-api/billing-tiering/tier";
import { PolicyActionCategory, PolicyActionInstantMessageLookup, PolicyActionSubType } from "./policy-actions";
import { PolicyConditionAsset, PolicyConditionBoolean, PolicyConditionCategory, PolicyConditionFinding, PolicyConditionProduct, PolicyConditionSubType } from "./policy-conditions";
import { PolicyItemType } from "./policy-item-base";
import { PolicyItemCategory, PolicySubType } from "./policy-items";
import { PolicyRuleItemKey } from "./policy-rule";
import { PolicyTriggerCategory, PolicyTriggerSubType } from "./policy-triggers";

export type DescriptorCategory = PolicyItemCategory | PolicyTriggerCategory | PolicyConditionCategory | PolicyActionCategory;

export type Descriptor<C extends DescriptorCategory = DescriptorCategory> = {
  name: string;
  description: string;
  shortDescription?: string;
  extendedDescription?: string;
  icon: `mdi-${string}`;
  tier: Tier;
  category: C;
};

export type PolicyDescriptorKey<T extends PolicyRuleItemKey = PolicyRuleItemKey> = keyof (typeof POLICY_DESCRIPTIONS)[PolicySubType][T];

const { global, secrets, permissions, code_risk, prioritization } = PolicySubType;
const { policy, trigger, condition, action } = PolicyItemType;

export const BOOLEAN_CONDITION_DESCRIPTIONS: Readonly<Record<PolicyConditionBoolean["sub"], Descriptor<PolicyConditionCategory>>> = {
  [PolicyConditionSubType.always]: {
    name: "Always (TRUE)",
    description: "Execute actions without any conditions",
    icon: "mdi-check-circle-outline",
    tier: Tier.free,
    category: "Logical"
  },
  [PolicyConditionSubType.or]: {
    name: "Any of (OR)",
    description: "Only execute actions when at least one condition is met",
    icon: "mdi-set-all",
    tier: Tier.free,
    category: "Logical"
  },
  [PolicyConditionSubType.and]: {
    name: "All of (AND)",
    description: "Only execute actions when all conditions are met",
    icon: "mdi-set-center",
    tier: Tier.free,
    category: "Logical"
  },
  [PolicyConditionSubType.not]: {
    name: "Reverse (NOT)",
    description: "Only execute actions when the following condition is not met",
    icon: "mdi-orbit-variant",
    tier: Tier.free,
    category: "Logical"
  }
} as const;

export const ASSET_CONDITION_DESCRIPTIONS: Readonly<Record<PolicyConditionAsset["sub"], Descriptor<PolicyConditionCategory>>> = {
  [PolicyConditionSubType.scm]: {
    name: "Source Control Management",
    description: "Asset belongs to the selected SCM integration",
    icon: "mdi-git",
    tier: Tier.free,
    category: "Asset"
  },
  [PolicyConditionSubType.org]: {
    name: "Organization",
    description: "Asset belongs to the selected organization",
    icon: "mdi-source-repository-multiple",
    tier: Tier.free,
    category: "Asset"
  },
  [PolicyConditionSubType.project]: {
    name: "Project",
    description: "Asset belongs to the selected project (inconclusive for SCMs without projects)",
    icon: "mdi-calendar",
    tier: Tier.free,
    category: "Asset"
  },
  [PolicyConditionSubType.repo]: {
    name: "Repository",
    description: "Asset belongs to the selected repository",
    icon: "mdi-source-repository",
    tier: Tier.free,
    category: "Asset"
  },
  [PolicyConditionSubType.branch]: {
    name: "Branch",
    description: "Asset belongs to the selected branch",
    icon: "mdi-source-branch",
    tier: Tier.free,
    category: "Asset"
  },
  [PolicyConditionSubType.target_branch]: {
    name: "Target Branch",
    description: "The target branch of the asset belongs to the selected branch",
    icon: "mdi-source-pull",
    tier: Tier.team,
    category: "Asset"
  },
  [PolicyConditionSubType.product]: {
    name: "Product",
    description: "Asset belongs to the selected product",
    icon: "mdi-badge-account-outline",
    tier: Tier.free,
    category: "Asset"
  },
  [PolicyConditionSubType.topic]: {
    name: "Topic",
    description: "Asset has the selected topic",
    icon: "mdi-newspaper",
    tier: Tier.free,
    category: "Asset"
  }
} as const;

export const PRODUCT_CONDITION_DESCRIPTIONS: Readonly<Record<PolicyConditionProduct["sub"], Descriptor<PolicyConditionCategory>>> = {
  [PolicyConditionSubType.has_product_matching_labels]: {
    name: "Has product matching labels",
    description: "Choose when actions should be executed based on product labels",
    icon: "mdi-tag",
    tier: Tier.free,
    category: "Product"
  },
  [PolicyConditionSubType.product_labels]: {
    name: "Labels",
    description: "Only execute actions when all labels are present in a product",
    icon: "mdi-tag",
    tier: Tier.free,
    category: "Product"
  },
  [PolicyConditionSubType.product_or]: {
    name: "OR",
    description: "Only execute actions when at least one condition is met",
    icon: "mdi-set-all",
    tier: Tier.free,
    category: "Product"
  },
  [PolicyConditionSubType.product_and]: {
    name: "AND",
    description: "Only execute actions when all conditions are met",
    icon: "mdi-set-center",
    tier: Tier.free,
    category: "Product"
  },
  [PolicyConditionSubType.product_not]: {
    name: "NOT",
    description: "Only execute actions when the following condition is not met",
    icon: "mdi-orbit-variant",
    tier: Tier.free,
    category: "Product"
  }
} as const;

export const FINDING_TYPE_DESCRIPTIONS: Readonly<Record<CodeRiskFindingType | SecretFindingType, { name: string; description?: string }>> = {
  [CodeRiskFindingType.SAST]: {
    name: "SAST",
    description: "Static Application Security Testing"
  },
  [CodeRiskFindingType.SCA]: {
    name: "SCA",
    description: "Software Composition Analysis"
  },
  [CodeRiskFindingType.IAC]: {
    name: "IaC",
    description: "Infrastructure as Code"
  },
  [CodeRiskFindingType.LICENSE]: {
    name: "License"
  },
  [CodeRiskFindingType.REPUTATION]: {
    name: "Reputation"
  },
  [SecretFindingType.SECRET]: {
    name: "Secret"
  }
} as const;

export const FINDING_CONDITION_DESCRIPTIONS = {
  [PolicyConditionSubType.severity]: {
    name: "Severity",
    description: "Finding severity is equal to or higher than the selected severity",
    icon: "mdi-gauge",
    tier: Tier.free,
    category: "Finding"
  },
  [PolicyConditionSubType.sla]: {
    name: "Exceeded SLA",
    description: "The Service Level Agreement (SLA) has been exceeded",
    icon: "mdi-calendar-today",
    tier: Tier.business,
    category: "Finding"
  },
  [PolicyConditionSubType.type]: {
    name: "Finding Type",
    description: "Finding type is the selected type",
    icon: "mdi-table-cog",
    tier: Tier.free,
    category: "Finding"
  },
  [PolicyConditionSubType.validation]: {
    name: "Secret Validation Status",
    description: "The secret finding validation status is the selected status",
    icon: "mdi-check-decagram-outline",
    tier: Tier.free,
    category: "Finding"
  },
  [PolicyConditionSubType.secret_types]: {
    name: "Secret Types",
    description: "The secret finding type match the provided types",
    icon: "mdi-key-chain-variant",
    tier: Tier.free,
    category: "Finding"
  },
  [PolicyConditionSubType.sast_property]: {
    name: "SAST Property",
    description: "The finding's SAST property matches the provided expressions (inconclusive for non-SAST findings)",
    icon: "mdi-focus-field",
    tier: Tier.enterprise,
    category: "Static Application Security Testing (SAST)"
  },
  [PolicyConditionSubType.package_age]: {
    name: "Package Age",
    description: "The finding's package age is within the given range (inconclusive for non-package findings)",
    icon: "mdi-human-cane",
    tier: Tier.enterprise,
    category: "Package"
  },
  [PolicyConditionSubType.package_cvss_score]: {
    name: "CVSS Score",
    description: "The finding's highest Common Vulnerability Scoring System (CVSS) score is within the given range (inconclusive for non-SCA findings)",
    icon: "mdi-counter",
    tier: Tier.enterprise,
    category: "Software Composition Analysis (SCA)"
  },
  [PolicyConditionSubType.package_epss_score]: {
    name: "EPSS Score",
    description: "The finding's highest Exploit Prediction Scoring System (EPSS) score is within the given range (inconclusive for non-SCA findings)",
    icon: "mdi-trending-up",
    tier: Tier.enterprise,
    category: "Software Composition Analysis (SCA)"
  },
  [PolicyConditionSubType.package_openssf_scorecard]: {
    name: "OpenSSF Score",
    description: "The finding's Open Source Security Foundation (OpenSSF) scorecard is within the given range (inconclusive for non-reputation findings)",
    icon: "mdi-security-network",
    tier: Tier.enterprise,
    category: "Reputation"
  },
  [PolicyConditionSubType.package_kev]: {
    name: "Has KEV",
    description: "The finding has a vulnerability in the Known Exploit Vulnerability (KEV) database (inconclusive for non-SCA findings)",
    icon: "mdi-beaker-alert-outline",
    tier: Tier.enterprise,
    category: "Software Composition Analysis (SCA)"
  },
  [PolicyConditionSubType.package_license_classification]: {
    name: "License Classification",
    description: "The finding's license(s) match the provided classifications (inconclusive for non-license findings)",
    icon: "mdi-license",
    tier: Tier.enterprise,
    category: "License"
  },
  [PolicyConditionSubType.package_license_original]: {
    name: "License - Original",
    description: "The finding's original license(s) match the provided expressions (inconclusive for non-license findings)",
    icon: "mdi-license",
    tier: Tier.enterprise,
    category: "License"
  },
  [PolicyConditionSubType.package_license_spdx]: {
    name: "License - Standardized SPDX",
    description: "The finding's license(s) match the provided standardized System Package Data Exchange (SPDX) licenses (inconclusive for non-license findings)",
    icon: "mdi-license",
    tier: Tier.enterprise,
    category: "License"
  },
  [PolicyConditionSubType.package_manager]: {
    name: "Package Manager",
    description: "The finding matches the provided package managers (inconclusive for non-package findings)",
    icon: "mdi-package-variant-closed",
    tier: Tier.enterprise,
    category: "Package"
  },
  [PolicyConditionSubType.package_name_and_version]: {
    name: "Package Name and Version",
    description: "The finding's package name@version matches the provided expressions (inconclusive for non-package findings)",
    icon: "mdi-package-variant-closed",
    tier: Tier.enterprise,
    category: "Package"
  },
  [PolicyConditionSubType.blame_property]: {
    name: "Git Blame Property",
    description: "The finding's Git blame property matches the provided expressions",
    icon: "mdi-git",
    tier: Tier.enterprise,
    category: "Finding"
  },
  [PolicyConditionSubType.sca_has_fix]: {
    name: "SCA Finding Has a Fix",
    description: "The SCA finding has a fix available (inconclusive for non-SCA findings)",
    icon: "mdi-auto-fix",
    tier: Tier.enterprise,
    category: "Software Composition Analysis (SCA)"
  },
  [PolicyConditionSubType.path]: {
    name: "File path",
    description: "The Finding's full file path matches the provided pattern",
    icon: "mdi-file-tree-outline",
    tier: Tier.enterprise,
    category: "Asset"
  }
} as const satisfies Readonly<Record<PolicyConditionFinding["sub"], Descriptor<PolicyConditionCategory>>>;

export const ACTION_DESCRIPTIONS = {
  [PolicyActionSubType.instant_message]: {
    name: "Send an Instant Message",
    description: "Send an instant message to a given user or channel",
    icon: "mdi-message",
    tier: Tier.team,
    category: "Instant Messaging"
  },
  [PolicyActionSubType.instant_message_lookup]: {
    name: "Recipient Lookup",
    description: "Looks up user(s) based on the event context",
    icon: "mdi-account-search",
    tier: Tier.team,
    category: "Instant Messaging"
  },
  [PolicyActionSubType.instant_message_static]: {
    name: "Specific Recipient",
    description: "Select a specific user or channel from a list",
    icon: "mdi-account-group",
    tier: Tier.team,
    category: "Instant Messaging"
  },
  [PolicyActionSubType.block_pr]: {
    name: "Fail Status Check",
    description: "Fails the status check which prevents the pull request from being merged (branch protection policy must require checks to pass to enforce)",
    extendedDescription: `Blocks the merging of a pull request to the <strong>Default Branch</strong>. Branch protection <strong>must be enabled</strong> to properly enforce this action (<a href="#/risks/git-posture/hardening/unenforceable-policy">view unenforced branches</a>)`,
    icon: "mdi-progress-close",
    tier: Tier.business,
    category: "Source Code Management"
  },
  [PolicyActionSubType.reset_branch]: {
    name: "Reset Branch",
    description: `Reset the branch to its pre-push state and create mitigated branch with masked secrets without losing code changes.`,
    icon: "mdi-source-branch-refresh",
    tier: Tier.business,
    category: "Mitigation"
  },
  [PolicyActionSubType.pr_comment]: {
    name: "Comment on Pull Request",
    description: `Add a comment to the pull request with context about the specific finding`,
    icon: "mdi-comment-text",
    tier: Tier.business,
    category: "Instant Messaging"
  },
  [PolicyActionSubType.pr_comment_recognition]: {
    name: "Recognize developer contribution as a PR comment",
    description: `Recognize the developer's contribution by adding a comment to the pull request`,
    icon: "mdi-comment-check",
    tier: Tier.business,
    category: "Instant Messaging"
  },
  [PolicyActionSubType.pr_comment_recognition_aggregated]: {
    name: "",
    description: ``,
    icon: "mdi-",
    tier: Tier.business,
    category: "Instant Messaging"
  },
  [PolicyActionSubType.pr_comment_mention_lookup]: {
    name: "Mention Lookup",
    description: "Looks up user(s) based on the event context",
    icon: "mdi-account-search",
    tier: Tier.business,
    category: "Instant Messaging"
  },
  [PolicyActionSubType.pr_comment_mention_static]: {
    name: "Specific Mention",
    description: "Select a specific user or group from a list",
    icon: "mdi-account-group",
    tier: Tier.team,
    category: "Instant Messaging"
  },
  [PolicyActionSubType.add_additional_reviewers]: {
    name: "Add Additional Reviewer(s)",
    description: "Add additional reviewer(s) to a given PR",
    icon: "mdi-account-plus",
    tier: Tier.business,
    category: "Source Code Management"
  },
  [PolicyActionSubType.add_additional_reviewers_lookup]: {
    name: "Reviewer Lookup",
    description: "Looks up user(s) based on the event context",
    icon: "mdi-account-search",
    tier: Tier.business,
    category: "Source Code Management"
  },
  [PolicyActionSubType.add_additional_reviewers_static]: {
    name: "Specific Reviewer",
    description: "Select a specific user or channel from a list",
    icon: "mdi-account-group",
    tier: Tier.business,
    category: "Source Code Management"
  },
  [PolicyActionSubType.create_issue]: {
    name: "Create Issue",
    description: "Create an issue",
    icon: "mdi-record-circle-outline",
    tier: Tier.business,
    category: "Issue Management"
  },
  [PolicyActionSubType.create_issue_inline]: {
    name: "Create Issue (Specific)",
    description: "Create an issue based on a specified policy configuration",
    icon: "mdi-record-circle-outline",
    tier: Tier.business,
    category: "Issue Management"
  },
  [PolicyActionSubType.create_issue_product_mapped]: {
    name: "Create Issues (Product Mapped)",
    extendedDescription: `Create an issue for every <a href="#/inventory/products" target="_blank">product</a> that matches the finding, based on the "Issue Management" configuration in the product`,
    description: "Create an issue based on the issue management rules configured in matching products",
    icon: "mdi-record-circle-outline",
    tier: Tier.business,
    category: "Issue Management"
  }
} as const satisfies Readonly<Record<PolicyActionSubType, Descriptor<PolicyActionCategory>>>;

export const ACTION_INSTANT_MESSAGE_DESCRIPTIONS: Readonly<Record<CommunicationIntegrationType, Descriptor<PolicyActionCategory>>> = {
  slack: {
    name: "Slack",
    description: "Send a message to a given Slack user or channel",
    icon: "mdi-slack",
    tier: Tier.team,
    category: "Instant Messaging"
  },
  msteams: {
    name: "Microsoft Teams",
    description: "Send a message to a given Microsoft Teams user or channel",
    icon: "mdi-microsoft-teams",
    tier: Tier.team,
    category: "Instant Messaging"
  }
} as const;

export const ACTION_PR_COMMENT_DESCRIPTIONS: Readonly<Record<ScmIntegrationType, Descriptor<PolicyActionCategory>>> = {
  github: {
    name: "GitHub",
    description: "Mention a GitHub user or group",
    icon: "mdi-github",
    tier: Tier.team,
    category: "Source Code Management"
  },
  "azure-devops": {
    name: "Microsoft Azure DevOps",
    description: "Mention a Microsoft Azure DevOps user or group",
    icon: "mdi-microsoft-azure-devops",
    tier: Tier.team,
    category: "Source Code Management"
  },
  "bitbucket-server": {
    name: "Bitbucket Server",
    description: "Mention a Bitbucket Server user or group",
    icon: "mdi-bitbucket",
    tier: Tier.team,
    category: "Source Code Management"
  },
  "bitbucket-cloud": {
    name: "Bitbucket Cloud",
    description: "Mention a Bitbucket Cloud user or group",
    icon: "mdi-bitbucket",
    tier: Tier.team,
    category: "Source Code Management"
  },
  gitlab: {
    name: "GitLab",
    description: "Mention a GitLab user or group",
    icon: "mdi-gitlab",
    tier: Tier.team,
    category: "Source Code Management"
  }
} as const;

export const ACTION_PR_REVIEWER_DESCRIPTIONS: Readonly<Record<ScmIntegrationType, Descriptor<PolicyActionCategory>>> = {
  github: {
    name: "GitHub",
    description: "Add a GitHub user or group as reviewer",
    icon: "mdi-github",
    tier: Tier.business,
    category: "Source Code Management"
  },
  "azure-devops": {
    name: "Microsoft Azure DevOps",
    description: "Add a Microsoft Azure DevOps user or group as reviewer",
    icon: "mdi-microsoft-azure-devops",
    tier: Tier.business,
    category: "Source Code Management"
  },
  "bitbucket-server": {
    name: "Bitbucket Server",
    description: "Add a Bitbucket Server user or group as reviewer",
    icon: "mdi-bitbucket",
    tier: Tier.team,
    category: "Source Code Management"
  },
  "bitbucket-cloud": {
    name: "Bitbucket Cloud",
    description: "Add a Bitbucket Cloud user or group as reviewer",
    icon: "mdi-bitbucket",
    tier: Tier.team,
    category: "Source Code Management"
  },
  gitlab: {
    name: "GitLab",
    description: "Add a GitLab user or group as reviewer",
    icon: "mdi-gitlab",
    tier: Tier.team,
    category: "Source Code Management"
  }
} as const;

export const ACTION_LOOKUP_DESCRIPTIONS: Readonly<Record<NonNullable<PolicyActionInstantMessageLookup["to"]>["lookup"], Descriptor<PolicyActionCategory>>> = {
  pusher: {
    name: "Code Pusher",
    description: "The user who pushed the code",
    icon: "mdi-account-arrow-up",
    tier: Tier.team,
    category: "Instant Messaging"
  },
  author: {
    name: "Code Author",
    description: "The user who authored the code",
    icon: "mdi-account-edit",
    tier: Tier.team,
    category: "Instant Messaging"
  },
  committer: {
    name: "Code Committer",
    description: "The user who committed the code",
    icon: "mdi-account-check",
    tier: Tier.team,
    category: "Instant Messaging"
  },
  product: {
    name: "Product Owner(s)",
    description: "The product owner(s) associated with the triggering asset",
    icon: "mdi-badge-account-outline",
    tier: Tier.team,
    category: "Instant Messaging"
  }
} as const;

export const POLICY_ITEM_DESCRIPTIONS: Readonly<Record<PolicySubType, Descriptor<PolicyItemCategory>>> = {
  [global]: {
    name: "Global Policy Conditions",
    description:
      "Global policy condition rules are applied before any other policy rule conditions. Only findings that match at least one of these rules will be considered during scans that Arnica conducts. This includes all real-time and routine scanning.\n⚠️ Any finding that does not meet at least one global policy condition will be completely ignored by Arnica and will not appear in risk reports.",
    icon: "mdi-filter",
    tier: Tier.free,
    category: "Policy"
  },
  [secrets]: {
    name: "Secrets",
    description:
      "Arnica regularly scans for secrets within your existing code and monitors for newly added secrets on push. Once a suspected secret is found, Arnica validates that secret and provides details, such as which users have accessed it recently and how long the secret has been present. Secrets policies can also prompt action(s) to be taken, such as notifying a user / channel on Microsoft Teams / Slack.",
    icon: "mdi-form-textbox-password",
    tier: Tier.free,
    category: "Policy"
  },
  [code_risk]: {
    name: "Code Risk",
    description:
      "Arnica regularly scans for risks within your existing code and monitors for newly introduced risks on push. Once a suspected vulnerability is found, Arnica provides details, such as what type of risk was located, and where the vulnerability exists. Code Risk policies can also prompt action(s) to be taken, such as notifying a channel or developer.",
    icon: "mdi-code-json",
    tier: Tier.free,
    category: "Policy"
  },
  [permissions]: {
    name: "Permissions",
    description:
      "Arnica analyzes the behavior of each identity within your integrated source code management systems to determine if each identity is actively using their given permissions. Permissions which are deemed excessive are flagged, and Arnica will recommend that they be reduced. Should members of a team display inconsistent use of the team’s permissions, Arnica may also suggest reassigning permissions directly to specific identities instead of providing permissions to a full team.<br><br>Self Service Permission functionality allows users to request permissions directly from their existing collaboration tools. Policies can also be set to respond to these requests. Responses can be customized to automatically grant permissions, appropriately notify a given audience upon request, or both.",
    icon: "mdi-shield-account",
    tier: Tier.free,
    category: "Policy"
  },
  [prioritization]: {
    name: "Prioritization",
    description: "Arnica regularly scans your repositories for business priority indicators.",
    icon: "mdi-priority-high",
    tier: Tier.free,
    category: "Policy"
  }
} as const;

const POLICY_TRIGGER_USER_CREATED_ISSUE_DESCRIPTOR = {
  [PolicyTriggerSubType.user_create_issue]: {
    name: "User Created Issue",
    description: "Triggers when a user manually creates an issue",
    shortDescription: "Manual Issue",
    icon: "mdi-gesture-double-tap",
    tier: Tier.enterprise,
    category: "Workflow"
  }
} as const satisfies Record<typeof PolicyTriggerSubType.user_create_issue, Descriptor<PolicyTriggerCategory>>;
const POLICY_TRIGGER_USER_DISMISSED_FINDING_DESCRIPTOR = {
  [PolicyTriggerSubType.user_dismissed_finding]: {
    name: "User Dismissed Finding via ChatOps",
    description: "Triggers when a user dismisses a finding using instant messaging",
    shortDescription: "Dismissed via ChatOps",
    icon: "mdi-chat-remove",
    tier: Tier.enterprise,
    category: "Workflow"
  }
} as const satisfies Record<typeof PolicyTriggerSubType.user_dismissed_finding, Descriptor<PolicyTriggerCategory>>;

export const POLICY_TRIGGER_DESCRIPTIONS = {
  [global]: {} as const,
  [secrets]: {
    [PolicyTriggerSubType.detected_on_push]: {
      name: "Secret detected on code push",
      description: "Triggers when a secret is detected on push",
      shortDescription: "Push",
      tier: Tier.team,
      icon: "mdi-flash",
      category: "Real-Time Scanning"
    },
    [PolicyTriggerSubType.detected_on_pr]: {
      name: "Secret detected on pull request",
      description: "Triggers when a new secret is detected on a pull request.",
      shortDescription: "Pull Request",
      tier: Tier.team,
      icon: "mdi-source-pull",
      category: "Real-Time Scanning"
    },
    [PolicyTriggerSubType.detected_on_scan]: {
      name: "Secret detected during a routine scan",
      description: "Triggers when a secret is detected on routine scan",
      shortDescription: "Routine Scan",
      tier: Tier.free,
      icon: "mdi-alarm-multiple",
      category: "Routine Scanning"
    },
    ...POLICY_TRIGGER_USER_CREATED_ISSUE_DESCRIPTOR,
    ...POLICY_TRIGGER_USER_DISMISSED_FINDING_DESCRIPTOR
  } as const,
  [permissions]: {} as const,
  [code_risk]: {
    [PolicyTriggerSubType.detected_on_push]: {
      name: "Code risk detected on push",
      description: "Triggers when a new code risk is detected on push",
      shortDescription: "Push",
      tier: Tier.team,
      icon: "mdi-flash",
      category: "Real-Time Scanning"
    },
    [PolicyTriggerSubType.detected_on_pr]: {
      name: "Code risk detected on pull request",
      description: "Triggers when a new code risk is detected on a pull request.",
      shortDescription: "Pull Request",
      tier: Tier.team,
      icon: "mdi-source-pull",
      category: "Real-Time Scanning"
    },
    [PolicyTriggerSubType.code_risk_fixed]: {
      name: "Code risk resolved on pull request",
      description: "Triggers when previously fixed code risks are included in a pull request",
      shortDescription: "Fixed",
      tier: Tier.team,
      icon: "mdi-party-popper",
      category: "Real-Time Scanning"
    },
    [PolicyTriggerSubType.detected_on_scan]: {
      name: "Code risk detected during a routine scan",
      description: "Triggers when a code risk is detected on routine scan",
      shortDescription: "Routine Scan",
      tier: Tier.free,
      icon: "mdi-alarm-multiple",
      category: "Routine Scanning"
    },
    ...POLICY_TRIGGER_USER_CREATED_ISSUE_DESCRIPTOR,
    ...POLICY_TRIGGER_USER_DISMISSED_FINDING_DESCRIPTOR
  } as const,
  [prioritization]: {
    [PolicyTriggerSubType.detected_on_scan]: {
      name: "Priority indicator detected during a routine scan",
      description: "Triggers when a priority indicator is detected",
      shortDescription: "Routine Scan",
      icon: "mdi-alarm-multiple",
      tier: Tier.free,
      category: "Routine Scanning"
    }
  } as const
} as const satisfies Readonly<Record<PolicySubType, Readonly<{ [key in PolicyTriggerSubType]?: Descriptor<PolicyTriggerCategory> }>>>;

export const POLICY_CONDITION_DESCRIPTIONS: Readonly<Record<PolicySubType, Readonly<{ [key in PolicyConditionSubType]?: Descriptor<PolicyConditionCategory> }>>> = {
  [global]: { ...BOOLEAN_CONDITION_DESCRIPTIONS, ...ASSET_CONDITION_DESCRIPTIONS, ...FINDING_CONDITION_DESCRIPTIONS, ...PRODUCT_CONDITION_DESCRIPTIONS } as const,
  [secrets]: { ...BOOLEAN_CONDITION_DESCRIPTIONS, ...ASSET_CONDITION_DESCRIPTIONS, ...FINDING_CONDITION_DESCRIPTIONS, ...PRODUCT_CONDITION_DESCRIPTIONS } as const,
  [permissions]: { ...BOOLEAN_CONDITION_DESCRIPTIONS, ...ASSET_CONDITION_DESCRIPTIONS, ...FINDING_CONDITION_DESCRIPTIONS, ...PRODUCT_CONDITION_DESCRIPTIONS } as const,
  [code_risk]: { ...BOOLEAN_CONDITION_DESCRIPTIONS, ...ASSET_CONDITION_DESCRIPTIONS, ...FINDING_CONDITION_DESCRIPTIONS, ...PRODUCT_CONDITION_DESCRIPTIONS } as const,
  [prioritization]: {
    [PolicyConditionSubType.always]: BOOLEAN_CONDITION_DESCRIPTIONS[PolicyConditionSubType.always]
  } as const
} as const;

export const POLICY_ACTION_DESCRIPTIONS: Readonly<Record<PolicySubType, Readonly<{ [key in PolicyActionSubType]?: Descriptor<PolicyActionCategory> }>>> = {
  [global]: {} as const,
  [secrets]: { ...ACTION_DESCRIPTIONS } as const,
  [permissions]: {} as const,
  [code_risk]: { ...ACTION_DESCRIPTIONS } as const,
  [prioritization]: {} as const
} as const;

export const POLICY_DESCRIPTIONS = {
  [global]: {
    [policy]: POLICY_ITEM_DESCRIPTIONS[global],
    [trigger]: POLICY_TRIGGER_DESCRIPTIONS[global],
    [condition]: POLICY_CONDITION_DESCRIPTIONS[global],
    [action]: POLICY_ACTION_DESCRIPTIONS[global]
  },
  [secrets]: {
    [policy]: POLICY_ITEM_DESCRIPTIONS[secrets],
    [trigger]: POLICY_TRIGGER_DESCRIPTIONS[secrets],
    [condition]: POLICY_CONDITION_DESCRIPTIONS[secrets],
    [action]: POLICY_ACTION_DESCRIPTIONS[secrets]
  },
  [permissions]: {
    [policy]: POLICY_ITEM_DESCRIPTIONS[permissions],
    [trigger]: POLICY_TRIGGER_DESCRIPTIONS[permissions],
    [condition]: POLICY_CONDITION_DESCRIPTIONS[permissions],
    [action]: POLICY_ACTION_DESCRIPTIONS[permissions]
  },
  [code_risk]: {
    [policy]: POLICY_ITEM_DESCRIPTIONS[code_risk],
    [trigger]: POLICY_TRIGGER_DESCRIPTIONS[code_risk],
    [condition]: POLICY_CONDITION_DESCRIPTIONS[code_risk],
    [action]: POLICY_ACTION_DESCRIPTIONS[code_risk]
  },
  [prioritization]: {
    [policy]: POLICY_ITEM_DESCRIPTIONS[prioritization],
    [trigger]: POLICY_TRIGGER_DESCRIPTIONS[prioritization],
    [condition]: POLICY_CONDITION_DESCRIPTIONS[prioritization],
    [action]: POLICY_ACTION_DESCRIPTIONS[prioritization]
  }
} as const;
