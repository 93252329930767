import { ApiBase } from "@/api/api-base";
import { FindingCount } from "$/ui-models/dashboard/finding-count";
import { BusinessImportanceLabel } from "$/business-importance";
import { ProductsList, UIDashboardProduct } from "$/ui-models/dashboard/ui-dashboard-product";
import { DashboardFilter, DashboardSort } from "$/ui-models/dashboard/dashboard-filter";
import { AxiosRequestConfig } from "axios";

interface GetProductOptions {
  page: number;
  pageSize: number;
  search?: string;
  filter: DashboardFilter;
  sort: DashboardSort;
  silent?: boolean;
}

class DashboardApi extends ApiBase {
  public constructor() {
    super({ pathPrefix: "dashboard", name: "DashboardApi" });
  }

  public async getFindingCounts(minImportance: BusinessImportanceLabel): Promise<FindingCount[]> {
    const url = await this.getUrl("counts");
    const result = await this.client.get<FindingCount[]>(url.toString(), {
      params: { minImportance }
    });
    return result.data;
  }

  public async getProducts({ page, pageSize, search, filter, sort, silent = false }: GetProductOptions): Promise<ProductsList> {
    const url = await this.getUrl("products");
    const config: AxiosRequestConfig = {
      url: url.toString(),
      method: "POST",
      silent,
      params: {
        page,
        pageSize
      },
      data: { filter, sort, search }
    };
    const result = await this.client.request<ProductsList>(config);
    return result.data;
  }
}

export const Dashboard = new DashboardApi();
