import { CallbackHandlerBase } from "./callback-handler-base";
import { IntegrationType } from "$/dynamo";
import { router } from "@/plugins";
import { Auth, Demo, Layout, Popup, Flags } from "@/state";
import { Integrations } from "@/state/integrations-state";

export abstract class ScmIntegrationCallbackHandlerBase extends CallbackHandlerBase {
  protected abstract createIntegration(query: URLSearchParams): Promise<IntegrationType>;

  protected async redirect(): Promise<void> {
    window.history.replaceState(null, "", window.location.origin);
    await router.push({ name: "loading" });
    try {
      if (!Layout.onboarding.dismissed) {
        //TODO: is this the right place to do this?
        Demo.enable();
      } else {
        if (Integrations.hasAtLeastOneSourceControlIntegration) {
          const hasInventoryV2 = (await Flags.tryGet("jobsV2"))?.inventoryV2;
          const targetPage = hasInventoryV2 ? "admin-jobs-v2-tasks" : "jobs";
          await router.push({ name: targetPage });
        } else {
          // will redirect to onboarding
          await router.push({ name: "home" });
        }
      }
    } catch (error) {
      console.warn("Failed to redirect", error);
    }
  }

  protected provideUserFeedback(): void {
    void Popup.info("Integration added successfully");
  }

  public async handle(query: URLSearchParams): Promise<void> {
    try {
      const integrationType = await this.createIntegration(query);

      Auth.authenticated && (await Integrations.init());

      this.sendToAnalytics(integrationType);

      await this.redirect();

      this.provideUserFeedback();
    } catch (e) {
      await this.handleIntegrationError(e);
    }
  }
}
