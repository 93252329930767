import { Jobs, JobsV2 } from "@/state";
import { FlagCheck } from "@/plugins/route-guards/sidebar-routes";

export class InventoryV2IsEnabledAndCompletedCheck extends FlagCheck<"jobsV2"> {
  public static readonly isTrue = new InventoryV2IsEnabledAndCompletedCheck();
  public static readonly isFalse = new InventoryV2IsEnabledAndCompletedCheck({ invert: true });

  public constructor(
    private readonly options?: {
      /**
       * Denotes if the result be should be inverted.
       * @default false
       */
      invert?: boolean;
    }
  ) {
    super("jobsV2", (f) => !!f?.inventoryV2);
  }

  private async hasV1Jobs(): Promise<boolean> {
    const jobs = await Jobs.allJobs;
    return jobs.some((job) => job.v !== 2);
  }

  private async isEnabledInternal(): Promise<boolean> {
    const isEnabledPromise = super.isEnabled();

    // if we don't have any v1 jobs, this is a new tenant, just return if the flag is enabled (should be true for all new tenants)
    if (!(await this.hasV1Jobs())) {
      return await isEnabledPromise;
    }

    // if we do have v1 jobs, this is an existing tenant with v1 inventory jobs, only enable the inventory v2 UI once the inventory scan job is complete
    // so users won't see empty inventory during the new scan.
    const [flagEnabled, jobComplete] = await Promise.all([isEnabledPromise, JobsV2.inventoryScan.isComplete()]);
    const enabled = flagEnabled && jobComplete;
    return enabled;
  }

  protected override async isEnabled(): Promise<boolean> {
    const invert = this.options?.invert || false;
    const enabled = await this.isEnabledInternal();
    return invert ? !enabled : enabled;
  }
}
