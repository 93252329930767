import { uuid } from "../../../../ui-models/uuid";
import { PolicyItemType } from "../../policy-item-base";
import type { MatcherType } from "../match-type";
import { PolicyCondition, PolicyConditionSubType } from "../policy-condition";

declare module "../policy-condition" {
  enum PolicyConditionSubType {
    scm = "scm",
    org = "org",
    project = "project",
    repo = "repo",
    branch = "branch",
    product = "product",
    topic = "topic"
  }
}

(PolicyConditionSubType as { scm: "scm" }).scm = "scm";
(PolicyConditionSubType as { org: "org" }).org = "org";
(PolicyConditionSubType as { project: "project" }).project = "project";
(PolicyConditionSubType as { repo: "repo" }).repo = "repo";
(PolicyConditionSubType as { branch: "branch" }).branch = "branch";
(PolicyConditionSubType as { product: "product" }).product = "product";
(PolicyConditionSubType as { topic: "topic" }).topic = "topic";

export interface PolicyConditionAsset extends PolicyCondition {
  sub:
    | PolicyConditionSubType.scm
    | PolicyConditionSubType.org
    | PolicyConditionSubType.project
    | PolicyConditionSubType.repo
    | PolicyConditionSubType.branch
    | PolicyConditionSubType.product
    | PolicyConditionSubType.target_branch
    | PolicyConditionSubType.topic;

  matcherType?: MatcherType;
}

export interface PolicyConditionAssetProduct extends PolicyConditionAsset {
  sub: PolicyConditionSubType.product;
  /**
   * @deprecated Use productId or v1Sortkey instead (existing policy conditions will be adapted)
   */
  name?: string;
  /**
   * Indicates if the product name is a v2 uuid (product has been migrated or created in v2)
   */
  productId?: uuid;
  /**
   * The v1 sortkey of the product. Saved in case a customer needs to revert to v1 (name will be overwritten during migration)
   */
  v1Sortkey?: string;
}

export const PolicyConditionAssetProduct = {
  is: (condition: PolicyCondition): condition is PolicyConditionAssetProduct => {
    return condition.sub === PolicyConditionSubType.product;
  }
} as const;

export const PolicyConditionAsset = {
  components: [
    {
      type: PolicyItemType.condition,
      sub: PolicyConditionSubType.scm
    },
    {
      type: PolicyItemType.condition,
      sub: PolicyConditionSubType.org
    },
    {
      type: PolicyItemType.condition,
      sub: PolicyConditionSubType.project
    },
    {
      type: PolicyItemType.condition,
      sub: PolicyConditionSubType.repo
    },
    {
      type: PolicyItemType.condition,
      sub: PolicyConditionSubType.branch
    },
    {
      type: PolicyItemType.condition,
      sub: PolicyConditionSubType.product
    },
    {
      type: PolicyItemType.condition,
      sub: PolicyConditionSubType.topic
    }
  ] as const
} as const;
